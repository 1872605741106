import React from 'react'
import { Card, CardContent, makeStyles, Paper } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        overflow: 'visible',
        margin: theme.spacing(4, 0),
        color: '#e5da74',
        '& .MuiPaper-root': {},
        '& .MuiCard-root': {
            overflow: 'visible',
        },
        '& .MuiCardContent-root': {
            padding: theme.spacing(0),
            // backgroundColor: '#282a36',
            // color: '#60e188',
            color: 'white',
            borderRadius: '25px',
            //boxShadow: '5px 5px rgb(0 0 0 / 27%)'
        },
        '& .cardFrom': {
            fontSize: '.8rem',
            textTransform: 'capitalize',
        },
        '& .MuiAvatar-root': {
            width: theme.spacing(6),
            height: theme.spacing(6),
        },
        '&.bubble': {
            display: 'flex',
            justifyContent: 'center',
            margin: theme.spacing(2),
            fontFamily: '"Carter One", serif',
            '& .MuiCardContent-root': {
                borderWidth: '2px',
                borderStyle: 'solid',
                position: 'relative',
                padding: theme.spacing(2),
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '-2px',
                    width: '0',
                    height: '0',
                    border: '20px solid transparent',
                    borderBottomWidth: '20px',
                    borderTop: '0',
                },
            },
            '&.gatzB': {
                '& .MuiCardContent-root': {
                    borderWidth: '0',
                    padding: '0',
                    margin: '20px 0',
                },
            },
            '&.juno': {
                justifyContent: 'flex-start',
                '& > div': {
                    maxWidth: '75%',
                },
                '& .MuiCardContent-root': {
                    backgroundColor: '#fff',
                    color: 'black',
                    fontSize: '0.875rem',
                    padding: theme.spacing(1, 2),
                    borderRadius: '0 24px 24px 24px',
                },
            },
            '&.leif': {
                justifyContent: 'flex-end',
                '& > div': {
                    maxWidth: '75%',
                },
                '& .MuiCardContent-root': {
                    backgroundColor: '#f0f104',
                    color: 'black',
                    fontSize: '0.875rem',
                    padding: theme.spacing(1, 2),
                    borderRadius: '24px 0 24px 24px',
                },
            },
            '&.dad': {
                justifyContent: 'flex-end',

                '& .MuiCardContent-root': {
                    borderRadius: '12px 0 12px 12px',
                    //backgroundColor: 'white',
                    color: '#5cffe2',
                    borderColor: 'white',
                    '&::before': {
                        right: '0',
                        borderRight: '0',
                        borderLeftColor: 'white',
                        marginRight: '-20px',
                    },
                },
            },
            '&.mum': {
                justifyContent: 'flex-start',

                '& .MuiCardContent-root': {
                    borderRadius: '0 12px 12px 12px',
                    //backgroundColor: 'white',
                    color: '#d99bff',
                    borderColor: 'white',
                    textAlign: 'right',
                    '&::before': {
                        left: '0',
                        borderLeft: '0',
                        borderRightColor: 'white',
                        marginLeft: '-20px',
                    },
                },
            },
        },
    },
}))

function Speech(props) {
    const classes = useStyles(props)
    return (
        <>
            <Paper
                className={`${classes.root} ${
                    props.from && 'bubble ' + props.from
                }`}
            >
                {/* {props.from && (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                        >
                            <Avatar>
                                <Icon>{avatars[props.from]}</Icon>
                            </Avatar>
                            <Box className="username">
                                {props.from === 'user'
                                    ? props.userName
                                    : props.from}
                            </Box>
                        </Box>
                    )} */}
                <Card elevation={0}>
                    <CardContent>{props.children}</CardContent>
                </Card>
            </Paper>
        </>
    )
}

export default Speech
