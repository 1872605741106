import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core'
// import ScrollAnimation from 'react-animate-on-scroll'
import 'animate.css/animate.min.css'
import PageFooter from './PageFooter'
import Speech from '../../components/Speech'
import Animation from '../../components/Animation'
// import Audio from '../../components/Audio'
import GateWayzBrand from '../../components/Brand'
// import track1 from '../../audio/audio1.mp3'
// import track2 from '../../audio/audio2.mp3'
// import wontGoOutside from '../../audio/wont-go-outside.mp3'
// import track4 from '../../audio/audio4.mp3'
// import hangOnJuno from '../../audio/hang-on-juno.mp3'
// import track3b from '../../audio/audio3b.mp3'
// import track3c from '../../audio/audio3c.mp3'
import Foggy from './Foggy'
import Delay from '../../components/Delay'

const useStyles = makeStyles({
    grid: {
        height: '100%',
        backgroundColor: 'black',
    },
    grid2: {
        height: '75%',
    },
    solidBkd: {
        position: 'fixed',
        left: '0',
        top: '0',
        width: '100%',
        height: '100%',
        backgroundColor: '#000',
        zIndex: '-1',
    },
    gatzBPos: {
        position: 'relative',
        zIndex: '2',
    },
    mum: {
        color: '#fe95e2',
    },
    dad: {
        color: '#95fe9e',
    },
    leif: {
        color: '#fff4a1',
    },
    juno: {
        color: '#8cd9ff',
    },
    speak: {
        display: 'inline-block',
        lineHeight: '1',
        padding: '0 3px',
        color: 'black',
    },
})

function Pages(props) {
    const classes = useStyles()
    const pageContent = [
        <Animation type="fadeIn">
            <Typography variant="body2" paragraph>
                Juno followed Leif through the emptying Bath streets. All around
                her people were hurrying home, hoping to be back well before
                curfew. You couldn’t blame them. The fog was arriving earlier
                each evening and staying longer each morning. Juno shivered and
                pulled her coat tighter. Then she took out the mask from her
                coat pocket and put it on. Leif rolled his eyes.
            </Typography>
        </Animation>,
        <Animation type="fadeIn">
            <Delay
                show="0"
                hide="3000"
                animate="scaleUp"
                fogPageLoaded={false}
                updateFogPageLoaded={props.updateFogPageLoaded}
            >
                <Box>
                    <Speech from="leif">We’re&nbsp;outside, Juno!</Speech>
                </Box>
            </Delay>
            <Delay show="3000" hide="6000" animate="scaleUp">
                <Box>
                    <Speech from="juno">It’s&nbsp;the&nbsp;law, Leif!</Speech>
                </Box>
            </Delay>
            <Delay show="6000" hide="9000" animate="scaleUp">
                <Box>
                    <Speech from="juno">
                        Look, if you don’t want me here ...
                    </Speech>
                </Box>
            </Delay>
            <Delay show="9000" hide="13500" animate="scaleUp">
                <Box>
                    <Speech from="leif">
                        Course I do. Who else is going to believe me when I work
                        out what’s going on?
                    </Speech>
                </Box>
            </Delay>
            <Delay
                show="13500"
                animate="fadeIn"
                fogPageLoaded={true}
                updateFogPageLoaded={props.updateFogPageLoaded}
            >
                <Typography variant="body2" paragraph>
                    He flashed a grin at her, but it wasn’t his easy grin. Not
                    the one that reached his eyes and said he trusted her. Juno
                    hadn’t seen that grin for months. She missed it, she missed
                    him. But things were different now.
                </Typography>
            </Delay>
        </Animation>,
        <Animation type="fadeIn">
            <Typography variant="body2" paragraph>
                In the Abbey courtyard, Juno wasn’t surprised to see the closed
                shops, their shutters battered. It was so quiet here now, Juno
                wanted to go home instantly, wanted to be back inside with Mum
                and Dad discussing ridiculous conspiracy theories, not out here,
                alone, listening to Leif’s. Still, when he handed her a packet
                of crisps, she took them, and she sat on the bench facing the
                Roman Baths, keeping the regulation metre apart.
            </Typography>
        </Animation>,
        <Animation type="fadeIn">
            <Typography variant="body2" paragraph>
                She’d stay for this packet, that’s all. As they crunched, a
                little rain fell onto the courtyard and Juno thought she
                remembered where the buskers used to play, right there in front
                of the entrance to the Baths, back before the Fog made the city
                empty and ghostly. It felt so long ago, it seemed like a dream,
                but it was only a year since the fog came, a little more maybe.
                Leif unpacked a woollen blanket and laid it over his legs,
                offering her the other side. She didn’t take it, that would mean
                shuffling too close: the fact Leif even offered made her weirdly
                angry.
            </Typography>
        </Animation>,
        <Animation type="fadeIn">
            <Delay
                show="0"
                animate="scaleUp"
                fogPageLoaded={false}
                updateFogPageLoaded={props.updateFogPageLoaded}
            >
                <Box>
                    <Speech from="juno">
                        How long are you going to stay out, anyway? Curfew is 8
                        tonight. You’re not surely still thinking …
                    </Speech>
                </Box>
            </Delay>
            <Delay show="4000" animate="scaleUp">
                <Box>
                    <Speech from="leif">
                        I’m staying all night, Juno! You said you would too.
                        We’re going to find out what this fog is, where it comes
                        from, remember?
                    </Speech>
                </Box>
            </Delay>
            <Delay show="8000" animate="scaleUp">
                <Box>
                    <Speech from="juno">
                        You know that’s stupid. Besdes, we’ll freeze.
                    </Speech>
                </Box>
            </Delay>
            <Delay
                show="11500"
                animate="scaleUp"
                fogPageLoaded={true}
                updateFogPageLoaded={props.updateFogPageLoaded}
            >
                <Box>
                    <Speech from="leif">Take the blanket, then!</Speech>
                </Box>
            </Delay>
        </Animation>,
        <Animation type="fadeIn">
            <Typography variant="body2" paragraph>
                He held it out. Still, she didn’t take it. None of this felt
                right. Being out beyond curfew, breaking the law, lying to her
                parents. Besides, it was dangerous. Maybe this could kill them.
                She wasn’t even sure Leif was worth that. Although, a whole
                night with Leif in the dark … If they could touch, she could
                shuffle closer, lay her head against his shoulder. If things
                were different and there wasn’t some deadly fog ...
            </Typography>
        </Animation>,
        <Animation type="fadeIn">
            <Delay
                show="0"
                animate="scaleUp"
                fogPageLoaded={false}
                updateFogPageLoaded={props.updateFogPageLoaded}
            >
                <Box>
                    <Speech from="juno">
                        You have a plan then … if we end up lost and forgetful?
                    </Speech>
                </Box>
            </Delay>
            <Delay show="3000" animate="scaleUp">
                <Box>
                    <Speech from="leif">
                        The people who get like that are always alone. We’ve got
                        each other, and...
                    </Speech>
                </Box>
            </Delay>
            <Delay
                show="6500"
                animate="fadeIn"
                fogPageLoaded={true}
                updateFogPageLoaded={props.updateFogPageLoaded}
            >
                <Typography variant="body2" paragraph>
                    He pulled out PPE that the hospital would be proud of. A
                    camera, too. One of those old fashioned ones with film.
                </Typography>
            </Delay>
        </Animation>,
        <Animation type="fadeIn">
            <Delay
                show="0"
                animate="scaleUp"
                fogPageLoaded={false}
                updateFogPageLoaded={props.updateFogPageLoaded}
            >
                <Box>
                    <Speech from="leif">
                        We’ll wear this, then set this going, as well as the
                        phone video. I’ve got my computer back home tracking
                        where we are at all times so even if we do get lost,
                        someone will find us eventually.
                    </Speech>
                </Box>
            </Delay>
            <Delay show="6000" animate="scaleUp">
                <Box>
                    <Speech from="juno">Juno: And if we forget?</Speech>
                </Box>
            </Delay>
            <Delay
                show="8000"
                animate="scaleUp"
                fogPageLoaded={true}
                updateFogPageLoaded={props.updateFogPageLoaded}
            >
                <Box>
                    <Speech from="leif">
                        We’ve got each other. Didn’t we always say that if we
                        had each other, things would work out?
                    </Speech>
                </Box>
            </Delay>
        </Animation>,
        <Animation type="fadeIn">
            <Typography variant="body2" paragraph>
                Had they said that? Juno hardly remembered. It was getting more
                difficult now to remember how she and Leif used to be, how she
                was with anyone. He used to have a smell when she hugged him,
                didn’t he? She thought it’d reminded her of being in a forest,
                something like that. It was already getting dark. As she looked
                across to the Baths, she thought for a moment she could see mist
                rising up from the water, slinking over the walls towards them.
                But it was far, far too early for the fog yet.
            </Typography>
        </Animation>,
        <Animation type="fadeIn">
            <Delay
                show="0"
                animate="scaleUp"
                fogPageLoaded={false}
                updateFogPageLoaded={props.updateFogPageLoaded}
            >
                <Box>
                    <Speech from="juno">
                        I don’t like this, we should go. Leave the fog for the
                        experts to work out.
                    </Speech>
                </Box>
            </Delay>
            <Delay show="3000" animate="scaleUp">
                <Box>
                    <Speech from="leif">
                        The expert’s don’t know jack, you know that. They’re
                        playing some merry dance with us, lying, saying this fog
                        is just a weather thing … we know it’s more than that,
                        that it’s supernatural, from another world. And until we
                        prove it, nothing is ever going to get done.
                    </Speech>
                </Box>
            </Delay>
            <Delay
                show="10000"
                animate="scaleUp"
                fogPageLoaded={true}
                updateFogPageLoaded={props.updateFogPageLoaded}
            >
                <Box>
                    <Speech from="juno">
                        You’re beginning to sound like all the crackpots.
                    </Speech>
                </Box>
            </Delay>
        </Animation>,
        <Animation type="fadeIn">
            <Delay
                show="0"
                hide="4000"
                fogPageLoaded={false}
                updateFogPageLoaded={props.updateFogPageLoaded}
            >
                <Typography variant="body2" paragraph>
                    As soon as she said it, she wished she hadn’t. He glared at
                    her, pulled the blanket tighter around him.
                </Typography>
            </Delay>
            <Delay show="4000" animate="scaleUp">
                <Box>
                    <Speech from="leif">
                        Go then. You’re different now, anyway. All boring and
                        unbelieving. I don’t need you here.
                    </Speech>
                </Box>
            </Delay>
            <Delay show="8000" animate="scaleUp">
                <Box>
                    <Speech from="juno">I’d rather be boring than dead.</Speech>
                </Box>
            </Delay>
            <Delay
                show="10000"
                animate="scaleUp"
                fogPageLoaded={true}
                updateFogPageLoaded={props.updateFogPageLoaded}
            >
                <Box>
                    <Speech from="leif">You’re becoming dead anyway.</Speech>
                </Box>
            </Delay>
        </Animation>,
        <Animation type="fadeIn">
            <Typography variant="body2" paragraph>
                She tightened her jaw and waited for him to look at her. He
                didn’t. Once he would have hugged her and said sorry. Once, she
                would’ve too. Back when they were still best friends. What was
                she doing wasting her time, risking her life here? She stood up
                and walked away. This was stupid, couldn’t he see? Surely being
                safe was more important than anything. She felt cold and shivery
                now, just wanted to get away.
            </Typography>
        </Animation>,
        <Animation type="fadeIn">
            <Delay
                show="0"
                animate="scaleUp"
                fogPageLoaded={false}
                updateFogPageLoaded={props.updateFogPageLoaded}
            >
                <Box>
                    <Speech from="juno">
                        C’mon Leif, I’ll promise I’ll help you research it. This
                        is so stupid being out alone. You’re being stupid.
                    </Speech>
                </Box>
            </Delay>
            <Delay show="3000" animate="scaleUp">
                <Box>
                    <Speech from="leif">
                        I’m going to find out. I told you.
                    </Speech>
                </Box>
            </Delay>
            <Delay
                show="6000"
                animate="scaleUp"
                fogPageLoaded={true}
                updateFogPageLoaded={props.updateFogPageLoaded}
            >
                <Box>
                    <Speech from="juno">
                        Don’t you care? Don’t you care about …?
                    </Speech>
                </Box>
            </Delay>
        </Animation>,
        <Animation type="flicker">
            <Typography variant="body2" paragraph>
                Her words lodged in her throat. Truth was, she didn’t know what
                she wanted him to care about any more. Her? Them? Getting
                through this situation and going home? She turned back to find
                him and tell him, but he was gone. Everything was. In front of
                her was now thick grey.
            </Typography>
            <Typography variant="body2" paragraph>
                The fog. She turned around in a circle. Everything was grey.
                Everything fog. It had come so quick. No warning. How was she
                going to get back now?
            </Typography>
        </Animation>,
        <Animation type="flicker">
            <Delay show="0" animate="scaleUp">
                <Box>
                    <Speech from="juno">Leif? Leif!</Speech>
                </Box>
            </Delay>
        </Animation>,
        <Delay show="0" hide="10000" animate="fadeIn">
            <Animation type="flicker">
                <Typography variant="body2" paragraph>
                    But there was no sound. There was nothing. There was only
                    grey and damp. Only fog.
                </Typography>
            </Animation>
        </Delay>,
    ]
    // const pageContent1 = [
    //     <>
    //         <Typography variant="body2" paragraph>
    //             Juno hesitated. The front door was less than a metre away, it
    //             wouldn’t take much. A few steps, a click to close, and down the
    //             front garden and into the street; then on to meet Leif at the
    //             corner. She waited in the hallway, listening. The news in the
    //             living room talked about the mounting fog, the curfew. She heard
    //             Dad’s low running commentary, dissecting the latest theories,
    //             and then the intermittent indignation from Mum.
    //         </Typography>
    //     </>,
    //     <Animation type="fadeIn">
    //         <Audio track={track1} turnPage={turnPage}></Audio>
    //     </Animation>,
    //     <Animation type="fadeIn">
    //         <Typography variant="body2" paragraph>
    //             Like Mum, Juno knew these were just rumours. All the stuff about
    //             witches and spirits rising from the earth … the stuff about the
    //             Fog coming from portals from other worlds, too. The truth was,
    //             nobody knew where this fog came from, and people were just
    //             getting hysterical. They needed answers.
    //         </Typography>
    //     </Animation>,
    //     <Animation type="fadeIn">
    //         <Typography variant="body2" paragraph>
    //             Juno thought it too. The people who got lost came back after a
    //             day or two anyway, most of them did. Juno grabbed her bag of
    //             snacks. It was going to be a long night, might as well be
    //             prepared.
    //         </Typography>
    //     </Animation>,
    //     <Animation type="fadeIn">
    //         <Typography variant="body2" paragraph>
    //             She felt bad, leaving them. Especially when they didn’t know.
    //             But Leif couldn’t go alone, could he? Not tonight.
    //         </Typography>
    //     </Animation>,
    //     <>
    //         <Animation type="fadeIn">
    //             <Typography variant="body2" paragraph align="center">
    //                 Her phone beeped. Leif.
    //             </Typography>
    //         </Animation>
    //         <Delay show="2000" animate="scaleUp">
    //             <Speech from="leif">At the corner</Speech>
    //         </Delay>
    //         <Delay show="4000" animate="scaleUp">
    //             <Speech from="leif">Coming?</Speech>
    //         </Delay>
    //     </>,
    //     <Animation type="fadeIn">
    //         <Typography variant="body2" paragraph>
    //             Her tummy did a sort of shimmy. She pictured him slouching
    //             against the wall of number ten. Maybe he was the real reason she
    //             was willing to take such a risk tonight. Juno shook her head.
    //             She knew it as well as Mum or Leif did, there had to be a
    //             logical answer to this fog each night, and why it was growing;
    //             there had to be a valid reason why {props.userLocation} was the
    //             epicentre. And the sooner someone found it out, the sooner they
    //             could go back to normal.
    //         </Typography>
    //     </Animation>,
    //     <>
    //         <Box mb={2}>
    //             <Audio track={hangOnJuno} controlIsHidden></Audio>
    //         </Box>
    //         <Delay show="0" hide="2500" animate="scaleUp">
    //             <Box>
    //                 <Speech from="dad">Hang on a minute, Juno.</Speech>
    //             </Box>
    //         </Delay>
    //         <Delay show="2500" animate="fadeIn">
    //             <Box>
    //                 <Typography variant="body2" paragraph>
    //                     And here he was, heaved up from the couch and thudding
    //                     across the floorboards towards her. Mum was in the
    //                     hallway too, peering around Dad’s bulk. Juno knew they’d
    //                     never forgive her if they knew what she was planning;
    //                     hell, they’d never let her out the house.
    //                 </Typography>
    //             </Box>
    //         </Delay>
    //         <Delay show="2500" animate="fadeIn"></Delay>
    //     </>,
    //     <>
    //         <Box>
    //             <Audio track={wontGoOutside} controlIsHidden></Audio>
    //         </Box>

    //         <Delay show="0" animate="scaleUp">
    //             <Box>
    //                 <Speech from="dad">
    //                     You won’t go outside, will you love? Once you’re at
    //                     Leif’s, you’ll stay in.
    //                 </Speech>
    //             </Box>
    //         </Delay>
    //         <Delay show="4400" animate="scaleUp">
    //             <Box>
    //                 <Speech from="mum">
    //                     Oh, c’mon, you know she’s sensible
    //                 </Speech>
    //             </Box>
    //         </Delay>
    //         <Delay show="6600" animate="scaleUp">
    //             <Box>
    //                 <Speech from="dad">Hmm, even so.</Speech>
    //             </Box>
    //         </Delay>
    //     </>,
    //     <Animation type="fadeIn">
    //         <Typography variant="body2" paragraph>
    //             She blew air kisses, said she loved them, and was out into the
    //             garden and down the street.
    //         </Typography>
    //     </Animation>,
    //     <Delay show="0" animate="fadeIn">
    //         <Animation delay="5s" type="flicker">
    //             <Typography variant="body2" paragraph>
    //                 Leif held up the key to the Abbey tower as she got close,
    //                 flashed a grin that made her tummy twist. Don’t be stupid
    //                 Leif was a friend, her best friend, it didn’t matter how
    //                 gorgeous he was getting as he grew taller and got smarter;
    //                 she could never risk his friendship by suggesting any more.
    //             </Typography>
    //         </Animation>
    //     </Delay>,

    //     <Delay show="0" hide="10000" animate="fadeIn">
    //         <Animation delay="1s" type="flicker">
    //             <Typography variant="body2" paragraph>
    //                 Leif dug in his backpack and pulled out an assortment of
    //                 salty snacks – nuts, and salt and vinegar crisps. She smiled
    //                 and held up her own bag. Then she linked her arm in his, and
    //                 together, walked towards the deserted Bath Abbey.
    //             </Typography>
    //         </Animation>
    //     </Delay>,
    // ]
    const firstPage = 1
    const lastPage = pageContent.length
    const [pageNumber, setPageIndex] = useState(
        Number(localStorage.getItem('pageNumber')) || firstPage
    )

    localStorage.setItem('pageNumber', pageNumber)

    function turnPage(direction) {
        if (direction === 'next') {
            setPageIndex((prevPage) => {
                return prevPage + 1
            })
        } else if (direction === 'prev') {
            setPageIndex((prevPage) => {
                return prevPage - 1
            })
        }
    }

    useEffect(() => {
        return function cleanup() {
            window.scrollTo(0, 0)
        }
    })

    return (
        <>
            {/* {pageNumber === lastPage && <Foggy thickness="fog2"></Foggy>} */}
            <Grid
                container
                direction="column"
                wrap="nowrap"
                alignItems="center"
                justify="space-between"
                spacing={4}
                className={classes.grid}
            >
                <Grid item>
                    <Animation type="slideInTop">
                        <Typography variant="h6">
                            T H E &nbsp;&nbsp; F O G
                        </Typography>
                    </Animation>
                </Grid>
                <Grid item className={classes.pageContent}>
                    <Animation type="fadeIn">
                        <Box key={pageNumber}>
                            {pageContent[pageNumber - 1]}
                        </Box>
                    </Animation>
                    {pageNumber === lastPage && (
                        <>
                            <Foggy thickness="fog2"></Foggy>
                            <Box className={classes.gatzBPos} align="center">
                                <Delay show="20000" animate="fadeIn">
                                    <GateWayzBrand />
                                    <Animation type="scaleUp">
                                        <Speech from="gatzB">
                                            <Typography
                                                component="p"
                                                align="center"
                                            >
                                                Uh oh! Looks like we've got a
                                                problem {props.userName}. I know
                                                YOU can help, but I need time...
                                                {/* Since you're in{' '}
                                            {props.userLocation}, you may be
                                            able to help. Can you get to the
                                            Abbey Courtyard? */}
                                            </Typography>
                                            <br></br>
                                            <Typography
                                                component="p"
                                                align="center"
                                            >
                                                PART 2 Coming Soon! Email
                                                hello@redward.codes for the
                                                latest.
                                            </Typography>
                                        </Speech>
                                    </Animation>
                                    <Button
                                        onClick={() => props.resetStep()}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        Start Again
                                    </Button>
                                </Delay>
                            </Box>
                        </>
                    )}
                </Grid>
                <Grid item>
                    <Animation type="slideInBottom">
                        <PageFooter
                            turnPage={turnPage}
                            pageNumber={pageNumber}
                            firstPage={firstPage}
                            lastPage={lastPage}
                            fogPageLoaded={props.fogPageLoaded}
                        />
                    </Animation>
                </Grid>
            </Grid>
            <Box className={classes.solidBkd}></Box>
        </>
    )
}

export default Pages
