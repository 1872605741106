import React, { useCallback } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import Animation from '../../components/Animation'

const useStyles = makeStyles({
    foggyC: {
        position: 'fixed',
        left: '0',
        top: '0',
        width: '100%',
        height: '100%',
        zIndex: '1',
        //backgroundColor: '#ccc',
    },
    fog2: {
        backgroundImage: 'linear-gradient(rgba(0,0,0,0), rgba(175,175,175,1))',
        // backgroundColor: '#555',
    },
    fog1: {
        backgroundImage:
            'linear-gradient(rgba(0,0,0,0), rgba(175,175,175,0.5))',
        // backgroundColor: '#555',
    },
    // foggy: {
    //     width: '100%',
    //     height: '100%',
    // },
})

function fogit(canvas) {
    //var canvas = document.getElementById('foggy')

    if (!canvas || !canvas.getContext) {
        //console.log('canvas not found')
        return false
    }

    /********************
        Random Number
      ********************/

    function rand(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    /********************
        Var
      ********************/

    // canvas
    var ctx = canvas.getContext('2d')
    var X = (canvas.width = window.innerWidth)
    var Y = (canvas.height = window.innerHeight)

    /********************
        Animation
      ********************/

    window.requestAnimationFrame =
        window.requestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function (cb) {
            setTimeout(cb, 17)
        }

    /********************
        Cloud
      ********************/

    var cloudNum = 50
    var clouds = []
    var radiusMin = 200
    var radiusMax = 200

    if (X < 768) {
        cloudNum = 30
        radiusMin = 150
        radiusMax = 200
    }

    function Cloud(ctx, x, y) {
        this.ctx = ctx
        this.init(x, y)
    }

    Cloud.prototype.init = function (x, y) {
        this.x = x
        this.y = y
        this.r = rand(radiusMin, radiusMax)
        //this.r = 100
        //this.l = rand(50, 100)
        this.l = 100
        this.v = {
            x: rand(-2, 2) * Math.random(),
            y: rand(-2, 2) * Math.random(),
        }
        this.c = {
            r: rand(0, 255),
            g: rand(0, 255),
            b: rand(0, 255),
            a: 1,
        }
    }

    Cloud.prototype.wrapPosition = function () {
        if (this.x + this.r < 0) {
            this.x = X + this.r
        }
        if (this.x - this.r > X) {
            this.x = 0 - this.r
        }
        if (this.y + this.r < 0) {
            this.y = Y + this.r
        }
        if (this.y - this.r > Y) {
            this.y = 0 - this.r
        }
    }

    Cloud.prototype.updatePosition = function () {
        this.x += this.v.x
        this.y += this.v.y
    }

    Cloud.prototype.updateParams = function () {
        this.l -= 0.1
        if (this.l < 0) {
            this.v.x = rand(-2, 2) * Math.random()
            this.v.y = rand(-2, 2) * Math.random()
            this.l = rand(50, 100)
        }
    }

    Cloud.prototype.resize = function () {
        this.x = rand(0 - 100, X + 100)
        this.y = rand(0 - 100, Y + 100)
    }

    Cloud.prototype.render = function () {
        this.updatePosition()
        this.updateParams()
        this.wrapPosition()
        this.draw()
    }

    Cloud.prototype.gradient = function () {
        var col = '255,255,255'
        var g = this.ctx.createRadialGradient(
            this.x,
            this.y,
            0,
            this.x,
            this.y,
            this.r
        )
        g.addColorStop(0, 'rgba(' + col + ', ' + this.c.a * 1 + ')')
        g.addColorStop(0.5, 'rgba(' + col + ', ' + this.c.a * 0.5 + ')')
        g.addColorStop(1, 'rgba(' + col + ', ' + this.c.a * 0 + ')')
        return g
    }

    Cloud.prototype.draw = function () {
        ctx.save()
        ctx.beginPath()
        ctx.globalCompositeOperation = 'lighter'
        ctx.globalAlpha = 0.1
        ctx.fillStyle = this.gradient()
        ctx.arc(this.x, this.y, this.r, Math.PI * 2, false)
        ctx.fill()
        ctx.restore()
    }

    for (var i = 0; i < cloudNum; i++) {
        var cloud = new Cloud(
            ctx,
            rand(0 - 100, X + 100),
            rand(0 - 100, Y + 100)
        )
        clouds.push(cloud)
    }

    /********************
        Render
      ********************/

    function render() {
        ctx.clearRect(0, 0, X, Y)
        for (var i = 0; i < clouds.length; i++) {
            clouds[i].render()
        }
        requestAnimationFrame(render)
    }

    render()
}

function Foggy(props) {
    const classes = useStyles(props)
    const fogCanvas = useCallback((node) => {
        if (node !== null) {
            fogit(node)
        }
    }, [])

    // useEffect(() => {
    //     const canvas = document.getElementById('foggy')
    //     //canvas.width = window.innerWidth
    //     //canvas.height = window.innerHeight
    //     canvas.height = window.innerHeight
    //     canvas.width = window.innerWidth
    //     fogit(canvas)

    //     return () => {
    //         canvas.remove()
    //     }
    // }, [])

    return (
        <Box className={classes.foggyC}>
            <Animation type="fadeIn" duration="20s" delay="2s">
                <canvas
                    id="foggy"
                    ref={fogCanvas}
                    className={classes[props.thickness] || classes.fog1}
                ></canvas>
            </Animation>
        </Box>
    )
}

export default Foggy
