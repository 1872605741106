import React from 'react'
import { Box, makeStyles } from '@material-ui/core'

function Animation(props) {
    const classes = useStyles(props)
    const types = {
        scaleUp: {
            root: classes.scaleUp,
        },
        rotateFast: {
            root: classes.rotateFast,
        },
        rotateSlow: {
            root: classes.rotateSlow,
        },
        scaleInHorLeft: {
            root: classes.scaleInHorLeft,
        },
        slideInTop: {
            root: classes.slideInTop,
        },
        slideInRight: {
            root: classes.slideInRight,
        },
        slideInLeft: {
            root: classes.slideInLeft,
        },
        slideInBottom: {
            root: classes.slideInBottom,
        },
        flicker: {
            root: classes.flicker,
        },
        flickerIn: {
            root: classes.flickerIn,
        },
        fadeIn: {
            root: classes.fadeIn,
        },
        fadeInLeft: {
            root: classes.fadeInLeft,
        },
        fadeInRight: {
            root: classes.fadeInRight,
        },
        fadeInTop: {
            root: classes.fadeInTop,
        },
        fadeInBottom: {
            root: classes.fadeInBottom,
        },
    }
    return <Box className={types[props.type].root}>{props.children}</Box>
}

const useStyles = makeStyles({
    scaleInHorLeft: {
        animation:
            '$scale-in-hor-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
    },
    '@keyframes scale-in-hor-left': {
        '0%': {
            transform: 'scaleX(0)',
            transformOrigin: '0% 0%',
            opacity: '1',
        },
        '100%': {
            transform: 'scaleX(1)',
            transformOrigin: '0% 0%',
            opacity: '1',
        },
    },
    scaleUp: {
        animation:
            '$scale-up-center 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55) both',
        animationDelay: (props) => props.delay || null,
    },
    '@keyframes scale-up-center': {
        '0%': {
            transform: 'scale(0.75)',
            opacity: '0',
        },
        '100%': {
            transform: 'scale(1)',
            opacity: '1',
        },
    },
    rotateFast: {
        animation: '$rotate-fast 0.6s ease-in-out infinite both',
    },
    '@keyframes rotate-fast': {
        '0%': {
            transform: 'rotate(0)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
    rotateSlow: {
        animation: '$rotate-slow 3.6s linear infinite both',
    },
    '@keyframes rotate-slow': {
        '0%': {
            transform: 'rotate(0)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
    slideInTop: {
        animation:
            '$slide-in-top 0.6s cubic-bezier(0.075, 0.820, 0.165, 1) both',
    },
    '@keyframes slide-in-top': {
        '0%': {
            transform: 'translateY(-50px)',
            opacity: '0',
        },
        '100%': {
            transform: 'translateY(0)',
            opacity: '1',
        },
    },
    slideInBottom: {
        animation:
            '$slide-in-bottom 0.6s cubic-bezier(0.075, 0.820, 0.165, 1) both',
    },
    '@keyframes slide-in-bottom': {
        '0%': {
            transform: 'translateY(50px)',
            opacity: 0,
        },
        '100%': {
            transform: 'translateY(0)',
            opacity: 1,
        },
    },
    slideInLeft: {
        animation:
            '$slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
        animationDelay: (props) => props.delay || null,
    },
    '@keyframes slide-in-left': {
        '0%': {
            transform: 'translateX(-10px)',
            opacity: '0',
        },
        '100%': {
            transform: 'translateX(0)',
            opacity: '1',
        },
    },
    slideInRight: {
        animation:
            '$slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
        animationDelay: (props) => props.delay || null,
    },
    '@keyframes slide-in-right': {
        '0%': {
            transform: 'translateX(10px)',
            opacity: '0',
        },
        '100%': {
            transform: 'translateX(0)',
            opacity: '1',
        },
    },
    fadeIn: {
        animation: '$fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both',
        animationDelay: (props) => props.delay || null,
        animationDuration: (props) => props.duration || '0.6s',
    },
    '@keyframes fade-in': {
        '0%': {
            transform: 'translateZ(-80px)',
            opacity: '0',
        },
        '100%': {
            transform: 'translateZ(0)',
            opacity: '1',
        },
    },
    fadeInLeft: {
        animation:
            '$fadeInLeft 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both',
        animationDelay: (props) => props.delay || null,
    },
    '@keyframes fadeInLeft': {
        '0%': {
            transform: 'translateX(-20px)',
            opacity: '0',
        },
        '100%': {
            transform: 'translateX(0)',
            opacity: '1',
        },
    },
    fadeInRight: {
        animation:
            '$fadeInRight 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both',
        animationDelay: (props) => props.delay || null,
    },
    '@keyframes fadeInRight': {
        '0%': {
            transform: 'translateX(20px)',
            opacity: '0',
        },
        '100%': {
            transform: 'translateX(0)',
            opacity: '1',
        },
    },
    fadeInTop: {
        animation:
            '$fadeInTop 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both',
        animationDelay: (props) => props.delay || null,
    },
    '@keyframes fadeInTop': {
        '0%': {
            transform: 'translateY(-20px)',
            opacity: '0',
        },
        '100%': {
            transform: 'translateY(0)',
            opacity: '1',
        },
    },
    fadeInBottom: {
        animation:
            '$fadeInBottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both',
        animationDelay: (props) => props.delay || null,
    },
    '@keyframes fadeInBottom': {
        '0%': {
            transform: 'translateY(20px)',
            opacity: '0',
        },
        '100%': {
            transform: 'translateY(0)',
            opacity: '1',
        },
    },
    flicker: {
        animation: '$flicker 4s linear infinite both',
        animationDelay: (props) => props.delay || null,
    },
    '@keyframes flicker': {
        '0%': {
            opacity: '1',
        },
        '100%': {
            opacity: '1',
        },
        '31.98%': {
            opacity: '1',
        },
        '32%': {
            opacity: '0',
        },
        '32.8%': {
            opacity: '0',
        },
        '32.82%': {
            opacity: '1',
        },
        '34.98%': {
            opacity: '1',
        },
        '35%': {
            opacity: '0',
        },
        '35.7%': {
            opacity: '0',
        },
        '35.72%': {
            opacity: '1',
        },
        '36.98%': {
            opacity: '1',
        },
        '37%': {
            opacity: '0',
        },
        '37.6%': {
            opacity: '0',
        },
        '37.62%': {
            opacity: '1',
        },
        '67.98%': {
            opacity: '1',
        },
        '68%': {
            opacity: '0',
        },
        '68.4%': {
            opacity: '0',
        },
        '68.42%': {
            opacity: '1',
        },
        '95.98%': {
            opacity: '1',
        },
        '96%': {
            opacity: '0',
        },
        '96.7%': {
            opacity: '0',
        },
        '96.72%': {
            opacity: '1',
        },
        '98.98%': {
            opacity: '1',
        },
        '99%': {
            opacity: '0',
        },
        '99.6%': {
            opacity: '0',
        },
        '99.62%': {
            opacity: '1',
        },
    },
    flickerIn: {
        animation: '$flicker-in 4s linear both',
        animationDelay: (props) => props.delay || null,
    },
    '@keyframes flicker-in': {
        '0%': {
            opacity: '1',
        },
        '5%': {
            opacity: '0',
        },
        '10%': {
            opacity: '1',
        },
        '20%': {
            opacity: '0',
        },
        '20.1%': {
            opacity: '1',
        },
        '40.6%': {
            opacity: '0',
        },
        '50%': {
            opacity: '0',
        },
        '50.1%': {
            opacity: '1',
        },
        '70.5%': {
            opacity: '1',
        },
        '100%': {
            opacity: '1',
        },
    },
})

export default Animation
