import React from 'react'
import { Box } from '@material-ui/core'
import Animation from './Animation'
import BrandLogo from './Brand/Logo'
// import BrandName from './Brand/Name'

function Loading(props) {
    props.timeout &&
        setTimeout(() => {
            props.callback()
        }, props.timeout)
    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <Animation type="rotateFast">
                <BrandLogo />
            </Animation>
            {/* <Box ml={1}>
                <BrandName />
            </Box> */}
        </Box>
    )
}

export default Loading
