import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import mapImage from '../images/old-bath-map-crop.jpg'

const useStyles = makeStyles({
    mapContainer: {
        overflow: 'auto',
        height: '100%',
        width: '100%',
        position: 'relative',
        margin: '16px 0',
    },
    map: {},
    markers: {
        '& > div': {
            position: 'absolute',
            borderRadius: '50%',
            border: 'white solid 2px',
            width: '20px',
            height: '20px',
        },
    },
    abbey: {
        left: '197px',
        top: '566px',
        backgroundColor: 'red',
    },
    queensSquare: {
        left: '425px',
        top: '340px',
        backgroundColor: 'green',
    },
    mrB: {
        left: '435px',
        top: '405px',
        backgroundColor: 'blue',
    },
    pulteneyBridge: {
        left: '385px',
        top: '618px',
        backgroundColor: 'blue',
    },
    milsom: {
        left: '485px',
        top: '435px',
        backgroundColor: 'blue',
    },
    gayStreet: {
        top: '372px',
        left: '472px',
        backgroundColor: 'blue',
    },
    see: {
        top: '527px',
        left: '302px',
        backgroundColor: 'green',
    },
})

function Map(props) {
    const classes = useStyles()
    return (
        <Box className={classes.mapContainer}>
            <div className={classes.markers}>
                <div
                    className={classes.abbey}
                    onClick={() => props.stepChange('MapAbbey')}
                ></div>
                <div
                    className={classes.queensSquare}
                    onClick={() => props.stepChange('MapQueensSquare')}
                ></div>
                <div className={classes.mrB}></div>
                <div
                    className={classes.pulteneyBridge}
                    onClick={() => props.stepChange('MapPulteneyBridge')}
                ></div>
                <div className={classes.milsom}></div>
                <div
                    className={classes.gayStreet}
                    onClick={() => props.stepChange('MapGayStreet')}
                ></div>
                <div className={classes.see}></div>
            </div>
            <img src={mapImage} alt="Map of Bath" className={classes.map} />
        </Box>
    )
}

export default Map
