import React from 'react'
import { makeStyles } from '@material-ui/core'
import SportsVolleyballIcon from '@material-ui/icons/SportsVolleyball'

const useStyles = makeStyles((theme) => ({
    logoContainer: {
        display: 'flex',
    },
    logo: {
        color: (props) => props.color || '#54ffb5',
    },
}))

function Logo(props) {
    const classes = useStyles(props)
    return (
        <div className={classes.logoContainer}>
            <SportsVolleyballIcon className={classes.logo} />
        </div>
    )
}

export default Logo
