import React from 'react'
import GateWayzBrand from '../components/Brand'
import { Button, Typography } from '@material-ui/core'
import Animation from '../components/Animation'
import Speech from '../components/Speech'

function TroubledStory(props) {
    return (
        <>
            <GateWayzBrand />
            <Animation type="scaleUp">
                <Speech from="gatzB">
                    <Typography component="p" align="center">
                        {props.userLocation} eh? OK, I've found a story called{' '}
                        <strong>THE FOG</strong>. I think you'll like it and you
                        may need headphones. Enjoy!
                    </Typography>
                </Speech>
            </Animation>
            <Button
                onClick={() => props.stepChange('next')}
                variant="outlined"
                color="primary"
            >
                The Fog
            </Button>
        </>
    )
}

export default TroubledStory
