import React from 'react'
import Brand from '../components/Brand'
import Animation from '../components/Animation'
import Speech from '../components/Speech'
import {
    Box,
    Button,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core'

const useStyles = makeStyles({
    textField: {
        '& input': {
            textAlign: 'center',
        },
        '& label': {
            textAlign: 'center',
            left: 0,
            right: 0,
            transformOrigin: 'center center',
            '&.Mui-focused': {
                transformOrigin: 'center center',
            },
        },
    },
})

function UserDetails(props) {
    const classes = useStyles()
    return (
        <>
            <Brand />
            <Animation type="scaleUp">
                <Box align="center">
                    <Speech from="gatzB">
                        <Typography component="p">
                            Great! To match you with a story, I'll need a little
                            info. Firstly, your name?
                        </Typography>
                        <Box mt={3}>
                            <TextField
                                name="userName"
                                value={props.userName}
                                variant="standard"
                                size="small"
                                onChange={(e) =>
                                    props.updateUserName(e.target.value)
                                }
                                className={classes.textField}
                            />
                        </Box>
                    </Speech>
                </Box>
            </Animation>
            <Button
                disabled={!props.userName}
                onClick={() => props.stepChange('next')}
                variant="outlined"
                color="primary"
            >
                Next
            </Button>
        </>
    )
}

export default UserDetails
