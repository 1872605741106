import React, { useState, useRef } from 'react'
import { Box, Button } from '@material-ui/core'
import PlayArrow from '@material-ui/icons/PlayArrow'
import Pause from '@material-ui/icons/Pause'
import soundWave from '../images/sound-wave.gif'
import soundWaveF1 from '../images/sound-wave-f1.gif'

function Audio(props) {
    const [audioIsPlaying, setAudioIsPlaying] = useState(
        props.autoPlay === false ? false : true
    )
    const audioPlayer = useRef(null)

    function audioEnded() {
        if (props.turnPage) {
            props.turnPage('next')
        } else {
            setAudioIsPlaying((prevState) => !prevState)
        }
    }

    function playAudio() {
        audioPlayer.current.play()
        setAudioIsPlaying((prevState) => !prevState)
    }

    function pauseAudio() {
        audioPlayer.current.pause()
        setAudioIsPlaying((prevState) => !prevState)
    }

    return (
        <>
            <audio
                autoPlay={audioIsPlaying}
                ref={audioPlayer}
                onEnded={() => audioEnded()}
            >
                <source src={props.track}></source>
            </audio>
            {!props.controlIsHidden &&
                (audioIsPlaying ? (
                    <>
                        <Box textAlign="center">
                            <Button onClick={pauseAudio} variant="outlined">
                                <Pause fontSize="large"></Pause>
                            </Button>
                        </Box>
                        <Box textAlign="center">
                            <img
                                src={soundWave}
                                width="250px"
                                alt="Playing"
                            ></img>
                        </Box>
                    </>
                ) : (
                    <>
                        <Box textAlign="center">
                            <Button onClick={playAudio} variant="outlined">
                                <PlayArrow fontSize="large"></PlayArrow>
                            </Button>
                        </Box>
                        <Box textAlign="center">
                            <img
                                src={soundWaveF1}
                                width="250px"
                                alt="Paused"
                            ></img>
                        </Box>
                    </>
                ))}
        </>
    )
}

export default Audio
