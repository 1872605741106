import React, { useEffect, useState } from 'react'
import Animation from '../components/Animation'

function Delay(props) {
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        // props.timeout &&
        //     setTimeout(() => {
        //         setIsVisible((prevState) => !prevState)
        //     }, props.timeout)
        let hideTimer
        let showTimer

        if (props.hide) {
            hideTimer = setTimeout(() => {
                setIsVisible(() => false)
            }, props.hide)
        }
        if (props.show) {
            showTimer = setTimeout(() => {
                setIsVisible(() => true)
                if (
                    typeof props.fogPageLoaded !== 'undefined' &&
                    props.updateFogPageLoaded
                ) {
                    props.updateFogPageLoaded(props.fogPageLoaded)
                }
            }, props.show)
        }
        return () => {
            clearTimeout(hideTimer)
            clearTimeout(showTimer)
        }
    }, [props.hide, props.show])

    return (
        isVisible &&
        (props.animate ? (
            <Animation type={props.animate}>{props.children}</Animation>
        ) : (
            <>{props.children}</>
        ))
    )
}

export default Delay
