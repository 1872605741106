import React from 'react'
import { Box, Button, makeStyles, Typography } from '@material-ui/core'
// import ScrollAnimation from 'react-animate-on-scroll'
import 'animate.css/animate.min.css'
// import PageFooter from './PageFooter'
import Speech from '../../components/Speech'
import Animation from '../../components/Animation'
import Audio from '../../components/Audio'
import track1 from '../../audio/audio1.mp3'
// import track2 from '../../audio/audio2.mp3'
// import wontGoOutside from '../../audio/wont-go-outside.mp3'
// import track4 from '../../audio/audio4.mp3'
// import hangOnJuno from '../../audio/hang-on-juno.mp3'
// import track3b from '../../audio/audio3b.mp3'
// import track3c from '../../audio/audio3c.mp3'
import Delay from '../../components/Delay'
import Rain from '../../images/rain.gif'

const useStyles = makeStyles({
    grid: {
        height: '100%',
        backgroundColor: 'black',
    },
    grid2: {
        height: '75%',
    },
    solidBkd: {
        position: 'fixed',
        left: '0',
        top: '0',
        width: '100%',
        height: '100%',
        backgroundColor: '#000',
        zIndex: '-1',
    },
    gatzB: {
        position: 'relative',
        zIndex: '2',
    },
    mum: {
        color: '#fe95e2',
    },
    dad: {
        color: '#95fe9e',
    },
    leif: {
        color: '#fff4a1',
    },
    juno: {
        color: '#8cd9ff',
    },
    speak: {
        display: 'inline-block',
        lineHeight: '1',
        padding: '0 3px',
        color: 'black',
    },
    poem: {
        height: '100vh',
        width: '100vw',
        paddingTop: '25vh',
    },
    rain: {
        backgroundImage: `url(${Rain})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        position: 'absolute',
        height: '100vh',
        width: '100vw',
        top: '0',
        left: '0',
    },
})

function Pages2(props) {
    const classes = useStyles()
    // const pageContent = [
    //     <>
    //         <Typography variant="body2" paragraph>
    //             Juno hesitated. The front door was less than a metre away, it
    //             wouldn’t take much. A few steps, a click to close, and down the
    //             front garden and into the street; then on to meet Leif at the
    //             corner. She waited in the hallway, listening. The news in the
    //             living room talked about the mounting fog, the curfew. She heard
    //             Dad’s low running commentary, dissecting the latest theories,
    //             and then the intermittent indignation from Mum.
    //         </Typography>
    //     </>,
    //     <Animation type="fadeIn">
    //         <Audio track={track1}></Audio>
    //     </Animation>,
    //     <Animation type="fadeIn">
    //         <Typography variant="body2" paragraph>
    //             Like Mum, Juno knew these were just rumours. All the stuff about
    //             witches and spirits rising from the earth … the stuff about the
    //             Fog coming from portals from other worlds, too. The truth was,
    //             nobody knew where this fog came from, and people were just
    //             getting hysterical. They needed answers.
    //         </Typography>
    //     </Animation>,
    //     <Animation type="fadeIn">
    //         <Typography variant="body2" paragraph>
    //             Juno thought it too. The people who got lost came back after a
    //             day or two anyway, most of them did. Juno grabbed her bag of
    //             snacks. It was going to be a long night, might as well be
    //             prepared.
    //         </Typography>
    //     </Animation>,
    //     <Animation type="fadeIn">
    //         <Typography variant="body2" paragraph>
    //             She felt bad, leaving them. Especially when they didn’t know.
    //             But Leif couldn’t go alone, could he? Not tonight.
    //         </Typography>
    //     </Animation>,
    //     <>
    //         <Animation type="fadeIn">
    //             <Typography variant="body2" paragraph align="center">
    //                 Her phone beeped. Leif.
    //             </Typography>
    //         </Animation>
    //         <Delay show="2000" animate="scaleUp">
    //             <Speech from="leif">At the corner</Speech>
    //         </Delay>
    //         <Delay show="4000" animate="scaleUp">
    //             <Speech from="leif">Coming?</Speech>
    //         </Delay>
    //     </>,
    //     <Animation type="fadeIn">
    //         <Typography variant="body2" paragraph>
    //             Her tummy did a sort of shimmy. She pictured him slouching
    //             against the wall of number ten. Maybe he was the real reason she
    //             was willing to take such a risk tonight. Juno shook her head.
    //             She knew it as well as Mum or Leif did, there had to be a
    //             logical answer to this fog each night, and why it was growing;
    //             there had to be a valid reason why {props.userLocation} was the
    //             epicentre. And the sooner someone found it out, the sooner they
    //             could go back to normal.
    //         </Typography>
    //     </Animation>,
    //     <>
    //         <Box mb={2}>
    //             <Audio track={hangOnJuno} controlIsHidden></Audio>
    //         </Box>
    //         <Delay show="0" hide="2500" animate="scaleUp">
    //             <Box>
    //                 <Speech from="dad">Hang on a minute, Juno.</Speech>
    //             </Box>
    //         </Delay>
    //         <Delay show="2500" animate="fadeIn">
    //             <Box>
    //                 <Typography variant="body2" paragraph>
    //                     And here he was, heaved up from the couch and thudding
    //                     across the floorboards towards her. Mum was in the
    //                     hallway too, peering around Dad’s bulk. Juno knew they’d
    //                     never forgive her if they knew what she was planning;
    //                     hell, they’d never let her out the house.
    //                 </Typography>
    //             </Box>
    //         </Delay>
    //         <Delay show="2500" animate="fadeIn"></Delay>
    //     </>,
    //     <>
    //         <Box>
    //             <Audio track={wontGoOutside} controlIsHidden></Audio>
    //         </Box>

    //         <Delay show="0" animate="scaleUp">
    //             <Box>
    //                 <Speech from="dad">
    //                     You won’t go outside, will you love? Once you’re at
    //                     Leif’s, you’ll stay in.
    //                 </Speech>
    //             </Box>
    //         </Delay>
    //         <Delay show="4400" animate="scaleUp">
    //             <Box>
    //                 <Speech from="mum">
    //                     Oh, c’mon, you know she’s sensible
    //                 </Speech>
    //             </Box>
    //         </Delay>
    //         <Delay show="6600" animate="scaleUp">
    //             <Box>
    //                 <Speech from="dad">Hmm, even so.</Speech>
    //             </Box>
    //         </Delay>
    //     </>,
    //     <Animation type="fadeIn">
    //         <Typography variant="body2" paragraph>
    //             She blew air kisses, said she loved them, and was out into the
    //             garden and down the street.
    //         </Typography>
    //     </Animation>,
    //     <Delay show="0" animate="fadeIn">
    //         <Animation delay="5s" type="flicker">
    //             <Typography variant="body2" paragraph>
    //                 Leif held up the key to the Abbey tower as she got close,
    //                 flashed a grin that made her tummy twist. Don’t be stupid
    //                 Leif was a friend, her best friend, it didn’t matter how
    //                 gorgeous he was getting as he grew taller and got smarter;
    //                 she could never risk his friendship by suggesting any more.
    //             </Typography>
    //         </Animation>
    //     </Delay>,

    //     <Delay show="0" hide="10000" animate="fadeIn">
    //         <Animation delay="1s" type="flicker">
    //             <Typography variant="body2" paragraph>
    //                 Leif dug in his backpack and pulled out an assortment of
    //                 salty snacks – nuts, and salt and vinegar crisps. She smiled
    //                 and held up her own bag. Then she linked her arm in his, and
    //                 together, walked towards the deserted Bath Abbey.
    //             </Typography>
    //         </Animation>
    //     </Delay>,
    // ]

    return (
        <>
            {props.location === 'mapAbbey' && (
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Animation type="fadeIn">
                        <Typography variant="body2" paragraph>
                            Now that much of the world is becoming seen again
                            and the fog is lifting, she returns to the Abbey
                            courtyard bench. Though Leif is still frozen. She
                            takes out the notebook he gave her, holds his hand,
                            and starts to write her story. Soon, Leif unfreezes
                            and she takes him to look at the new city she has
                            helped bring back.
                        </Typography>
                    </Animation>
                    <Animation type="slideInBottom">
                        <Box mt={5}>
                            <Button
                                onClick={() => props.stepChange('Map')}
                                variant="outlined"
                                color="primary"
                            >
                                Map
                            </Button>
                        </Box>
                    </Animation>
                </Box>
            )}
            {props.location === 'mapQueensSquare' && (
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Animation type="fadeIn">
                        <Audio track={track1}></Audio>
                    </Animation>
                    <Animation type="slideInBottom">
                        <Box mt={5}>
                            <Button
                                onClick={() => props.stepChange('Map')}
                                variant="outlined"
                                color="primary"
                            >
                                Map
                            </Button>
                        </Box>
                    </Animation>
                </Box>
            )}
            {props.location === 'mapPulteneyBridge' && (
                <>
                    <Box
                        display="flex"
                        flexDirection="column"
                        className={classes.poem}
                    >
                        <div className={classes.rain}></div>
                        <Box p={4} pl={5}>
                            <Delay show="1000" animate="slideInLeft">
                                <Typography variant="body2" paragraph>
                                    There was rain
                                </Typography>
                            </Delay>
                            <Box ml={5}>
                                <Delay show="4000" animate="slideInLeft">
                                    <Typography variant="body2" paragraph>
                                        light as fairy footsteps
                                    </Typography>
                                </Delay>
                            </Box>
                            <Box textAlign="left">
                                <Delay show="6000" animate="slideInLeft">
                                    <Typography variant="body2" paragraph>
                                        landing on
                                    </Typography>
                                </Delay>
                            </Box>
                            <Box ml={5}>
                                <Delay show="7000" animate="fadeIn">
                                    <Typography variant="body2" paragraph>
                                        her cheeks,
                                    </Typography>
                                    <Typography variant="body2" paragraph>
                                        her nose,
                                    </Typography>
                                </Delay>
                            </Box>

                            <Delay show="10000" animate="fadeIn">
                                <Typography variant="body2" paragraph>
                                    it seemed to push the fog away
                                </Typography>
                            </Delay>
                            <Delay show="15000" animate="slideInBottom">
                                <Box mt={5}>
                                    <Button
                                        onClick={() => props.stepChange('Map')}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        Map
                                    </Button>
                                </Box>
                            </Delay>
                        </Box>
                    </Box>
                </>
            )}
            {props.location === 'mapGayStreet' && (
                <Animation type="fadeIn">
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <Animation type="fadeIn">
                            <Typography
                                variant="body2"
                                paragraph
                                align="center"
                            >
                                Her phone beeped. Leif.
                            </Typography>
                        </Animation>
                        <Delay show="2000" animate="scaleUp">
                            <Speech from="dad">At the corner</Speech>
                        </Delay>
                        <Delay show="4000" animate="scaleUp">
                            <Speech from="dad">Coming?</Speech>
                        </Delay>
                        <Delay show="8000" animate="slideInBottom">
                            <Box mt={5}>
                                <Button
                                    onClick={() => props.stepChange('Map')}
                                    variant="outlined"
                                    color="primary"
                                >
                                    Map
                                </Button>
                            </Box>
                        </Delay>
                    </Box>
                </Animation>
            )}
        </>
    )
}

export default Pages2
