import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    palette: {
        type: 'dark',
        background: {
            default: '#282a36',
        },
        text: {
            primary: '#fff',
            secondary: '#000',
        },
        primary: {
            light: '#eeeeee',
            main: '#00f5c7',
            dark: 'rgba(0,0,0,0.35)', //'#393e46', // red #CC4E77
            contrastText: '#e5da74',
        },
        secondary: {
            light: '#ff4081',
            main: '#f5007b',
            dark: '#c51162',
            contrastText: 'white',
        },
    },
    props: {
        MuiPaper: {
            elevation: 0,
        },
    },
    overrides: {
        MuiButton: {
            root: {
                cursor: 'pointer',
                '&$disabled': {
                    color: 'rgba(0, 0, 0, 0.3)',
                },
            },
            outlined: {
                border: 'none',
            },
            outlinedPrimary: {
                color: '#000',
                borderColor: '#54ffb5',
                backgroundColor: '#54ffb5',
                borderRadius: '20px',
                fontFamily: '"Roboto Mono", monospace',
                fontSize: '0.75rem',
                textTransform: 'capitalize',
                '&:hover': {
                    backgroundColor: '#54ffb5',
                    borderColor: '#ffffff',
                    color: '#15161c',
                    //borderColor: '#61ff92',
                },
            },
        },
        MuiInputBase: {
            root: {
                color: 'white',
                fontFamily: '"Roboto Mono", monospace',
            },
        },
        MuiInput: {
            underline: {
                '&::before': {
                    borderBottomColor: 'white',
                },
                '&::after': {
                    borderBottomColor: 'white',
                },
            },
        },
        MuiFormLabel: {
            root: {
                color: 'white',
                fontFamily: '"Roboto Mono", monospace',
                fontSize: '.95rem',
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: 'transparent',
            },
        },
        MuiToolbar: {
            root: {
                justifyContent: 'center',
            },
        },
        MuiTypography: {
            body1: {
                fontSize: '.85rem',
                lineHeight: '1.6',
                fontFamily: '"Roboto Mono", monospace',
                fontWeight: '500',
                letterSpacing: '0.05rem',
            },
            body2: {
                fontSize: '1.125rem',
                //fontWeight: '600',
                fontFamily: '"Stylish", serif',
                lineHeight: '1.5',
                color: '#fff',
                textAlign: 'justify',
            },
            subtitle1: {
                fontWeight: '600',
                fontSize: '.75rem',
                fontFamily: '"Roboto Mono", monospace',
                color: '#fff',
                letterSpacing: '0.25rem',
            },
            subtitle2: {
                color: 'white',
            },
            h6: {
                fontFamily: '"Stylish", serif',
                fontSize: '1rem',
            },
        },
    },
})
