import React from 'react'
import { Button, Typography } from '@material-ui/core'
import Brand from '../components/Brand'
import Animation from '../components/Animation'
import Speech from '../components/Speech'

function Welcome(props) {
    return (
        <>
            <Brand />
            <Animation type="scaleUp">
                <Speech from="gatzB">
                    <Typography component="p" align="center">
                        Hey! I'm GATZ-B. I connect readers with stories like
                        never before! Wanna see?
                    </Typography>
                </Speech>
            </Animation>
            <Button
                onClick={() => props.stepChange('next')}
                variant="outlined"
                color="primary"
            >
                I wanna see!
            </Button>
        </>
    )
}

export default Welcome
