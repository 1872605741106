import React from 'react'
import { Box } from '@material-ui/core'
import BrandLogo from './Logo'
import BrandName from './Name'

function Brand(props) {
    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <BrandLogo color={props.color} />
            <Box ml={1}>
                <BrandName />
            </Box>
        </Box>
    )
}

export default Brand
