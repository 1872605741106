import React from 'react'
import { Box, makeStyles, IconButton, Typography } from '@material-ui/core'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'

const useStyles = makeStyles({
    pageFooter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        zIndex: '2',
    },
})

function PageFooter(props) {
    const classes = useStyles()
    return (
        <footer className={classes.pageFooter}>
            <IconButton
                disabled={props.firstPage === props.pageNumber && true}
                onClick={() => props.turnPage('prev')}
            >
                <ChevronLeft />
            </IconButton>
            <Box mx={2}>
                <Typography variant="body2">
                    {props.pageNumber} / {props.lastPage + 10}
                </Typography>
            </Box>
            <IconButton
                disabled={
                    (props.lastPage === props.pageNumber ||
                        !props.fogPageLoaded) &&
                    true
                }
                onClick={() => props.turnPage('next')}
            >
                <ChevronRight />
            </IconButton>
        </footer>
    )
}

export default PageFooter
