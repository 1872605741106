import React from 'react'
import { Typography } from '@material-ui/core'

function BrandName() {
    return (
        <Typography variant="subtitle1" component="h1">
            GATZ-B
        </Typography>
    )
}

export default BrandName
