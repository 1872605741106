import React, { useState } from 'react'
import { Button, Typography } from '@material-ui/core'
import Brand from '../components/Brand'
import Loading from '../components/Loading'
import Speech from '../components/Speech'
import Animation from '../components/Animation'

function UserLocation(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [isLocationDenied, setIsLocationDenied] = useState(false)

    function permissionGranted(data) {
        const api =
            'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
            data.coords.latitude +
            ',' +
            data.coords.longitude +
            '&key=AIzaSyBQPj3ai274MptZ3hFZSp1a9pD9u9Ds-Gs'
        fetch(api)
            .then((res) => res.json())
            .then((res) => {
                res.results[0]['address_components'].forEach((component) => {
                    if (
                        component.types.indexOf('postal_town') > -1 ||
                        component.types.indexOf('locality') > -1
                    ) {
                        props.updateUserLocation(component.long_name)
                        return
                    }
                })
                props.stepChange('next')
            })
            .catch((err) => {
                console.warn(err)
            })
    }
    function permissionDenied(error) {
        setIsLoading(() => false)
        setIsLocationDenied(() => true)
        console.warn(error)
    }
    function handleClick() {
        setIsLoading(() => true)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                permissionGranted,
                permissionDenied
            )
        }
    }
    const locationDenied = (
        <>
            <Brand />
            <Animation type="scaleUp">
                <Speech from="gatzB">
                    <Typography component="p" align="center">
                        Access denied.
                    </Typography>
                    <Typography component="p" align="center">
                        Would you like to use Bath as a location?
                    </Typography>
                </Speech>
            </Animation>
            <Button
                color="primary"
                onClick={() => props.stepChange('next')}
                variant="outlined"
            >
                Use Bath
            </Button>
        </>
    )
    const locationAllowed = (
        <>
            <Brand />
            <Animation type="scaleUp">
                <Speech from="gatzB">
                    <Typography component="p" align="center">
                        Thanks {props.userName}! I'll need access to your
                        location too, is that okay?
                    </Typography>
                </Speech>
            </Animation>
            <Button
                onClick={() => handleClick()}
                variant="outlined"
                color="primary"
            >
                Allow Access
            </Button>
        </>
    )
    return (
        <>
            {isLoading ? (
                <Loading />
            ) : isLocationDenied ? (
                locationDenied
            ) : (
                locationAllowed
            )}
        </>
    )
}

export default UserLocation
