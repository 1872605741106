import React, { useState } from 'react'
import { Container, CssBaseline, Grid, makeStyles } from '@material-ui/core'

import { ThemeProvider } from '@material-ui/core/styles'
import './styles/theme.scss'
import theme from './styles/theme'
import './styles/stars.scss'

import Loading from './components/Loading'
import Welcome from './screens/Welcome'
import UserDetails from './screens/UserDetails'
import UserLocation from './screens/UserLocation'
import TroubledStory from './screens/TroubledStory'
import Map from './screens/Map'
//import FogParticles from './screens/TheFog/FogParticles'
// import Foggy from './screens/TheFog/Foggy'
import TheFogPages from './screens/TheFog/Pages'
import TheMapPages from './screens/TheFog/Pages2'

const useStyles = makeStyles({
    '@global': {
        'html, body, #root': {
            height: '100%',
        },
        body: {
            // backgroundImage: 'radial-gradient(#ffffff0d 1px, transparent 0)',
            // backgroundSize: '2rem 2rem',
            background:
                'radial-gradient(ellipse at bottom, #282a36 0%, #000 100%)',
        },
    },
    stars: {
        position: 'fixed',
        top: '0',
        left: '0',
    },
    container: {
        height: '100%',
    },
    grid: {
        height: '100%',
    },
    section: {
        height: '100%',
        // overflowX: 'hidden',
    },
    header: {
        position: 'fixed',
        right: '0',
        bottom: '0',
        zIndex: '4',
        padding: '1rem',
        '& p': {
            cursor: 'pointer',
            display: 'inline-block',
            padding: '5px 10px',
            borderRadius: '3px',
            fontSize: '0.75rem',
            lineHeight: '1',
            margin: '0',
        },
    },
})

function App() {
    const classes = useStyles()
    const defaults = {
        userName: null,
        userLocation: 'Unknown',
        fogPageLoaded: true,
    }
    const steps = [
        // 'TheFogPages',
        'Loading',
        'Welcome',
        'UserDetails',
        'UserLocation',
        'Loading',
        'TroubledStory',
        'TheFogPages',
        'Map',
        'MapAbbey',
        'MapQueensSquare',
        'MapGayStreet',
        'MapPulteneyBridge',
    ]
    const [stepIndex, setStepIndex] = useState(
        Number(localStorage.getItem('stepIndex')) || 0
    )
    const [fogPageLoaded, setFogPageLoaded] = useState(defaults.fogPageLoaded)
    const [userName, setUserName] = useState(
        localStorage.getItem('userName') || defaults.userName
    )
    const [userLocation, setUserLocation] = useState(
        localStorage.getItem('userLocation') || defaults.userLocation
    )
    // const [isConnected, setIsConnected] = useState(false)

    const updateUserName = (userName) => {
        setUserName(userName)
        localStorage.setItem('userName', userName)
    }
    const updateUserLocation = (location) => {
        setUserLocation(location)
        localStorage.setItem('userLocation', location)
    }

    function stepChange(direction) {
        if (direction === 'next' && stepIndex < steps.length - 1) {
            setStepIndex((prevStep) => {
                return prevStep + 1
            })
        } else if (direction === 'prev' && stepIndex > 0) {
            setStepIndex((prevStep) => {
                return prevStep - 1
            })
        } else if (steps.indexOf(direction) > -1) {
            setStepIndex(steps.indexOf(direction))
        }
    }

    const updateFogPageLoaded = (newState) => {
        setFogPageLoaded((previousState) => {
            if (newState) return newState
            return !previousState
        })
    }

    function resetStep() {
        // localStorage.setItem('stepIndex', 0)
        // localStorage.setItem('pageNumber', 0)
        // localStorage.setItem('userName', null)
        // localStorage.setItem('userLocation', null)
        localStorage.clear()
        setStepIndex(1)
        //window.location.reload()
    }

    function stepView(step) {
        localStorage.setItem('stepIndex', stepIndex)
        //console.log(step, stepIndex)
        switch (step) {
            case 'Loading':
                return (
                    <Loading
                        callback={() => stepChange('next')}
                        timeout="2000"
                    />
                )
            case 'Welcome':
                return <Welcome stepChange={stepChange} />
            case 'UserLocation':
                return (
                    <>
                        <div id="stars" className={classes.stars}></div>
                        <UserLocation
                            stepChange={stepChange}
                            userName={userName}
                            userLocation={userLocation}
                            updateUserLocation={updateUserLocation}
                        />
                    </>
                )
            case 'UserDetails':
                return (
                    <UserDetails
                        stepChange={stepChange}
                        userName={userName}
                        updateUserName={updateUserName}
                    />
                )
            case 'TroubledStory':
                return (
                    <TroubledStory
                        stepChange={stepChange}
                        userLocation={userLocation}
                    />
                )
            case 'TheFogPages':
                return (
                    <>
                        <TheFogPages
                            stepChange={stepChange}
                            userLocation={userLocation}
                            userName={userName}
                            fogPageLoaded={fogPageLoaded}
                            updateFogPageLoaded={updateFogPageLoaded}
                            resetStep={resetStep}
                        />
                    </>
                )
            case 'Map':
                return (
                    <>
                        {/* <Foggy opacity="1"></Foggy> */}
                        <Map stepChange={stepChange} />
                    </>
                )
            case 'MapAbbey':
                return (
                    <TheMapPages location="mapAbbey" stepChange={stepChange} />
                )
            case 'MapQueensSquare':
                return (
                    <TheMapPages
                        location="mapQueensSquare"
                        stepChange={stepChange}
                    />
                )
            case 'MapGayStreet':
                return (
                    <TheMapPages
                        location="mapGayStreet"
                        stepChange={stepChange}
                    />
                )
            case 'MapPulteneyBridge':
                return (
                    <TheMapPages
                        location="mapPulteneyBridge"
                        stepChange={stepChange}
                    />
                )
            default:
                return 'No view found'
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {stepIndex < 6 && <div id="stars" className={classes.stars}></div>}
            {/* <FogParticles className="fade-in-fwd" stepIndex={stepIndex} /> */}
            <header className={classes.header}>
                <p style={{ opacity: 0.1 }} onClick={() => resetStep()}>
                    ...
                </p>
            </header>
            <section className={classes.section}>
                <Container maxWidth="xs" className={classes.container}>
                    <Grid
                        container
                        direction="column"
                        wrap="nowrap"
                        alignItems="center"
                        justify="center"
                        className={classes.grid}
                    >
                        {stepView(steps[stepIndex])}
                    </Grid>
                </Container>
            </section>
        </ThemeProvider>
    )
}

export default App
